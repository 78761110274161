import {
  dynamicRequest,
  get_candidate_report,
  dynamicClear,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "antd";
import { HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";

const CandidateReport = () => {
  const dispatch = useDispatch();

  const { items: candidate, loading } = useDynamicSelector(
    "getCandidatesReport"
  );

  const [candidate_list, setCandidateList] = useState([]);
  const [page_limit, setPageLimit] = useState(null); // Total records to fetch
  const [pageNumber, setPageNumber] = useState(null);
  const [is_download, setIsDownload] = useState(false);

  const handleFetchCandidates = async () => {
    try {
      let keys = [{ key: "getCandidatesReport", loading: true }];
      let query = get_candidate_report;
      let variables = { page_number: pageNumber, page_limit: 20 }; // Fetch 20 records at a time
      dispatch(dynamicRequest(keys, query, variables));
    } catch (error) {
      console.error("Error fetching candidates", error);
    }
  };

  useEffect(() => {
    if (pageNumber * 20 <= page_limit && !loading) {
      handleFetchCandidates();
    }
  }, [pageNumber, loading]);

  useEffect(() => {
    if (candidate?.length) {
      setCandidateList((prevList) => [...prevList, ...candidate]);

      if (candidate.length === 20 && candidate_list.length < page_limit) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      } else {
        setIsDownload(true);
      }
    }
  }, [candidate]);

  useEffect(() => {
    if (is_download) {
      downloadUserReport();
    }
  }, [is_download]);

  const downloadUserReport = async () => {
    try {
      // Assuming you need to make another API call after the first one to get additional data
      // Example API call could be here to get extra data
      // const response = await fetchAdditionalData();
      // Process the response if needed

      const inputFormat = candidate_list?.map((x) => ({
        Name: x.name,
        Mobile: x.mobile,
        Email: x.email,
        DOB: x.dob,
        Gender: x.gender,
        City: x.city,
        District: x.district_name,
        State: x.state_name,
      }));

      const columnWidths = [
        { wch: 25 }, // Name
        { wch: 12 }, // Mobile
        { wch: 20 }, // Email
        { wch: 10 }, // DOB
        { wch: 8 }, // Gender
        { wch: 15 }, // City
        { wch: 20 }, // District
        { wch: 15 }, // State
      ];
      const worksheet = XLSX.utils.json_to_sheet(inputFormat);

      worksheet["!cols"] = columnWidths;

      // Freeze the header row
      worksheet["!freeze"] = {
        xSplit: 0,
        ySplit: 1,
        topLeftCell: "A2",
        activePane: "bottomRight",
        state: "frozen",
      };
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "User List");

      // Save the workbook to a file
      setPageLimit(null);
      setPageNumber(null);
      XLSX.writeFile(workbook, "User_List.xlsx");
      dispatch(dynamicClear("getCandidatesReport"));
      console.log("File downloaded successfully");
    } catch (error) {
      console.error("Error creating or downloading the file", error);
    }
    setIsDownload(false);
    setPageNumber(1);
    setCandidateList([]);
  };
  const handle_click = () => {
    setPageLimit(500);
    setPageNumber(1); 
    setCandidateList([]); 
    setIsDownload(false); 
    handleFetchCandidates();
  };

  return (
    <div>
      <HStack space={"3"} justifyContent="center">
        <Button onClick={handle_click} type="primary" loading={loading}>
          User Report
        </Button>
      </HStack>
    </div>
  );
};

export default CandidateReport;
